import {
  SET_COMMON_CONFIG, SET_CURRENT_STEP, SET_CLICK_STEP, SET_IS_SETTING_COACH_MARK,SET_IS_BANNER_AVAILABLE
} from "./actionTypes"

export const setCommonConfig = response => {
  return {
    type: SET_COMMON_CONFIG,
    payload: response,
  }
}
export const setCurrentStep = payload => {
  return {
    type: SET_CURRENT_STEP,
    payload: payload,
  }
}
export const setClickCount = payload => {
  return {
    type: SET_CLICK_STEP,
    payload: payload,
  }
}
export const setIsSettingCoachMark = payload => {
  return {
    type: SET_IS_SETTING_COACH_MARK,
    payload: payload,
  }
}


export const setIsBannerAvailable = (payload) => {
  return {
    type: SET_IS_BANNER_AVAILABLE,
    payload: payload,
  };
};
