import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import HttpApi from 'i18next-http-backend';
import { url } from "./helpers/global_helper";

const language = localStorage.getItem("I18N_LANGUAGE")
if (!language) {
  localStorage.setItem("I18N_LANGUAGE", "id")
}
let base_url = window.location.origin;
let ext = url.API_PREFIX + url.PLAYER_MS_EXT + url.APPLICATION_EXT + '/listLanguageData?app_type=cardgame&lang=';
if (process.env.REACT_APP_ENABLE_LOCAL_API == 2) {
  ext = url.PLAYER_MS_EXT + url.APPLICATION_EXT + '/listLanguageData?app_type=cardgame&lang=';
}
const backendOptions = {
  loadPath: ((process.env.REACT_APP_ENABLE_LOCAL_API == 1) ? process.env.REACT_APP_API_URL : base_url) + ext + '{{lng}}',
  customHeaders: {
    Authorization: "Bearer " + localStorage.getItem(url.TOKEN_KEY),
    [url.LOBBY_MOB_PERM] : "new_tg_lob_mob_status",
    [url.LOBBY_WEB_PERM] : "new_tg_lob_web_status",
    // ...
  },
  allowMultiLoading: true,
  crossDomain: true
};
i18n
  .use(HttpApi)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    backend: backendOptions,
    lng: localStorage.getItem("I18N_LANGUAGE") || "id",
    fallbackLng: "id", // use id if detected lng is not available
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    react: {
      useSuspense: false
    }
  })
export default i18n;