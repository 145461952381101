import "./App.css";
import { useState, useEffect, useRef, lazy, Suspense } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import LoaderImg from "./assets/images/icons/ajax-loader.svg";
import CloseIcon from "./assets/images/modal/close.svg";
import wifiIcon from "./assets/images/icons/wifi_icon.svg";
import { setCommonConfig } from "./redux/actions";
import { makeAjax, url, respStatus, showMessage } from "./helpers/global_helper";
import audioManager from "./component/AudioManager";
import musicFile from "./assets/music/hovermusic.mp3";
const NavigationComponent = lazy(() => import("./component/NavigationComponent/NavigationComponent"));
const LaunchLobby = lazy(() => import("./component/LaunchLobby/LaunchLobby"));
const Nickname = lazy(() => import("./component/NickName/NickName"));
const LogoutMesage = lazy(() => import("./component/Common/LogoutMesage"));
const Modal = lazy(() => import("./component/Modal/Modal"));
const UnderMaintenance = lazy(() => import("./component/Common/UnderMaintenance"));
const SessionExpireMessage = lazy(() => import("./component/Common/SessionExpireMessage"));
const Bonus = lazy(() => import("./component/Bonus/Bonus"));
const AccessDenied = lazy(() => import("./component/Main/SubComponent/AccessDenied"));
const FestiveThemeBottomDesign = lazy(() => import("./component/FestiveTheme/FestiveThemeBottomDesign"));
const SnowFall = lazy(() => import("./component/FestiveTheme/SnowFall"));
function App(props) {
  const dispatch = useDispatch();

  const [partnerKey, setPartnerKey] = useState(localStorage.getItem("partner"));
  const [background, setBackground] = useState("");
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [deviceType, setDeviceType] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [width, setWidth] = useState(0);
  const [loadingComp, setLoadingComp] = useState(true);
  const [loader, setLoader] = useState("");
  // const clientLoader = loader || localStorage.getItem("loader");
  const clientLoader = loader;
  const loginToken = localStorage.getItem("token");
  const [showBonus, setShowBonus] = useState(false);
  const [loaderSpeed, setLoaderSpeed] = useState(false);
  const refreshLoaderSpeed = 10;
  const hasExecutedInitialTimeout = useRef(false);  // Keeps track of whether the initial timer has executed
  const festiveTheme = 0;
  const currentStep = useSelector((state) => state.CommonConfig.currentStep);
  // const track1Manager = new AudioManager(musicFile);

  useEffect(() => {
    if (
      !isLoading &&
      window.location.pathname !== "/logout" &&
      window.location.pathname !== "/sessionexpired" &&
      window.location.pathname !== "/nickname"
    ) {
      // const timer = setTimeout(() => {
      //   setShowBonus(true);
      // }, 5000000);
      // return () => clearTimeout(timer);

      if (!hasExecutedInitialTimeout.current) {
        const firstTimer = setTimeout(() => {
          setShowBonus(true);
          hasExecutedInitialTimeout.current = true;  // Mark the initial timer as executed

          // Start the repeating interval after the initial timeout
          const intervalId = setInterval(() => {
            setShowBonus(true);
          }, 60000); // Repeat every 1 minute

          // Cleanup the interval when the component unmounts
          return () => clearInterval(intervalId);
        }, 60000);  // Initial delay of 1 minute

        // Cleanup the first timer when the component unmounts or dependencies change
        return () => clearTimeout(firstTimer);
      }
    }
  }, [isLoading, window.location.pathname]);


  const fetchImgData = async (url) => {
    try {
      const response = await fetch(url);
      const data = await response.text();
      // Loader Image
      const base64Regex1 = /loading_lobby\s*=\s*"([^"]*)"/;
      const match1 = data.match(base64Regex1);
      if (match1 && match1.length > 1) {
        setLoader(match1[1]);
      } else {
        // console.error('404 Loader Image not found');
      }
      // Logo Image
      // const base64Regex2 = /logo\s*=\s*"([^"]*)"/;
      // const match2 = data.match(base64Regex2);
      // if (match2 && match2.length > 1) {
      //   setLogo(match2[1]);
      // } else {
      //   // console.error('404 Logo Image not found');
      // }
    } catch (error) {
      // console.error('Error fetching the JS file:', error);
    }
  };


  const fetchFtpLogo = async () => {
    // const searchParams = new URLSearchParams(location.search);
    // let operatorid = searchParams.get('operatorid');

    // let reqData = {
    //   web_id: operatorid ? operatorid : null,
    // };
    const response = await makeAjax(url.PLAYER_API.fetchLoader, [], url.PLAYER_MS_EXT);

    console.log("loader data", response.data);


    await fetchImgData(response.data.loaderJS);
  }
  useEffect(() => {
    fetchFtpLogo();
  }, [])

  const closeOnlinePopup = () => {
    setIsOnline(false);

  };
  const device = useSelector((state) => state.CommonConfig.device_type);

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);
    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, [device]);

  useEffect(() => {
    if (loginToken) {
      setTimeout(() => {
        setIsLoading(false)
      }, 1500);
    }
  }, [loginToken])

  useEffect(() => {
    if (window.location.pathname == "/logout") {
      setIsLoading(false)
    }
    if (window.location.pathname == "/nickname") {
      setIsLoading(false)
    }
  }, [window.location.pathname, loginToken])

  const helpRunfunction = () => {
    makeAjax(url.PLAYER_API.checkSession, {}, url.PLAYER_MS_EXT)
  }

  useEffect(() => {
    const interval = setInterval(() => {
      helpRunfunction()
    }, 120000);
    return () => clearInterval(interval);
  }, [])

  useEffect(() => {
    let interval;
    // const loaderValue = loaderSpeed ? 2 : refreshLoaderSpeed;          

    if (isLoading || loadingComp) {
      setWidth(0); // Reset width to 0 when loading starts
      interval = setInterval(() => {
        setWidth((prevWidth) => {
          const newWidth = prevWidth + 2;
          if (newWidth >= 100) {
            clearInterval(interval);
          }
          return newWidth;
        });
      }, 80);
    } else {
      setTimeout(() => {
        setWidth(100);
      }, 200);
    }
    return () => clearInterval(interval);
  }, [isLoading]);

  useEffect(() => {
    if (!isLoading && width >= 100) {
      setTimeout(() => {
        // localStorage.setItem('loaderValue', 5);
        setLoadingComp(false);
      }, 500);
    }
  }, [width, isLoading]);

  // useEffect(() => {
  //   if (width === 100 && !isLoading && !loadingComp) {
  //     audioManager.addAudio('background', musicFile, true, 0.05);
  //     audioManager.initPlayOnLoad('background', 0.05);

  //     return () => {
  //         audioManager.pause('background');
  //         audioManager.removeAudio('background');
  //     };
  //   }
  // }, [loadingComp, width, isLoading]);

  useEffect(() => {
    if (!loadingComp) {
      const startAudio = () => {
        audioManager.initPlayOnLoad('background', 0.05);
        window.removeEventListener('click', startAudio);
      };

      window.addEventListener('click', startAudio, { once: true });
      return () => window.removeEventListener('click', startAudio);
    }
  }, [loadingComp]);

  useEffect(() => {
    const handlePartnerNameChange = () => {
      setPartnerKey(localStorage.getItem("partner"));
    };
    window.addEventListener("partnerNameChange", handlePartnerNameChange);
    return () => {
      window.removeEventListener("partnerNameChange", handlePartnerNameChange);
    };
  }, []);

  useEffect(() => {
    setBackground(
      window.location.pathname === "/" ? "mainPageBgImage" : "backColorSet"
    );
    const handleLocationChange = () => {
      setBackground(
        window.location.pathname === "/" ? "mainPageBgImage" : "backColorSet"
      );
    };
    window.addEventListener("popstate", handleLocationChange);
    return () => {
      window.removeEventListener("popstate", handleLocationChange);
    };
  }, [background]);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 2000);
    return () => clearTimeout(timeout);
  }, []);

  const handleUrlChange = () => {
    setBackground(
      window.location.pathname === "/" ? "mainPageBgImage" : "backColorSet"
    );
    const handleLocationChange = () => {
      setBackground(
        window.location.pathname === "/" ? "mainPageBgImage" : "backColorSet"
      );
    };
    window.addEventListener("popstate", handleLocationChange);
    return () => {
      window.removeEventListener("popstate", handleLocationChange);
    };
  };
  const onClose = () => {
    document.getElementById("commonToaster").style.display = "none";
  };
  useEffect(() => {
    function isDevice() {
      const userAgent = navigator.userAgent;
      const isMobile =
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          userAgent
        );
      const isTablet = /iPad|Android/.test(userAgent) && !isMobile;
      if (isMobile) {
        return "Mobile";
      } else if (isTablet) {
        return "Tablet";
      } else {
        return "Desktop";
      }
    }

    // TEST

    window.isDevice = isDevice;
    const device = window.isDevice();
    setDeviceType(device);
    dispatch(setCommonConfig({ key: "device_type", value: device }));
    localStorage.setItem("device_type", device);
    return () => {
      delete window.isDevice;
    };
  }, [dispatch]);

  return (
    <>
      {
        festiveTheme === 1 && <SnowFall />
      }
      <div id="commonToaster" className="error-popup">
        <div className="error-popup-content">
          <span className="close" onClick={onClose}>
            &times;
          </span>
          <p id="commonToasterHeading" className="message"></p>
          <div className="header_bottom_border"></div>
          <p id="commonToasterBody" className="error-message"></p>
        </div>
      </div>

      {!loadingComp && (
        <div className="ajax_loader_container hideMe" id="ajax-loader">
          <div className="ajax_loader">
            <img src={LoaderImg} alt="" />
          </div>
        </div>
      )}

      {loadingComp && window.location.pathname !== "/sessionexpired" && (
        <div className="loader-background">
          <div id="ajax-loader" className="custom-loader">
            <div
              className="custom-loader-line"
              style={{ width: `${width}%` }}
            ></div>
            <div className="lds-ripple"></div>
          </div>
        </div>
      )}

      {loadingComp && window.location.pathname !== "/sessionexpired" && (
        <div className="loader-background">
          <div className="loader_img">
            {clientLoader && (
              <img src={clientLoader} alt="Loader" width="100%" height="100%" />
            )}
          </div>

          <div id="ajax-loader" className="custom-loader">
            <div
              className="custom-loader-line"
              style={{ width: `${width}%` }}
            ></div>
            {clientLoader !== "" && <div className="lds-ripple"></div>}
          </div>
          <div className="loader-footer">
            Copyright © 2016 {localStorage.getItem("partner")?.toUpperCase()}.
            All Rights Reserved.
          </div>
        </div>
      )}
      {!isOnline && (
        <>
          <div className="wifi_popup">
            <Modal closeButton={true} onCloseModal={closeOnlinePopup}>
              {/* <button onClick={closeOnlinePopup} className="btnclose">
              <img src={CloseIcon} alt="closeIcon" />
            </button> */}

              <div className="conn_lost_inner">
                <img src={wifiIcon} alt="wifi" />
                <h2></h2>
                <div className="notification_body">
                  <h3>Connection Lost</h3>
                  <p>Your connection appears to be off-line.</p>
                </div>
              </div>
            </Modal>
          </div>
        </>
      )}
      {showBonus && <Bonus />}

      <div
        className={`${background} allDimentionSet wrapper ${festiveTheme === 2 ? "newYearTheme" : ""} ${currentStep && (currentStep !== -1) ? `coachmark${currentStep}` : ""}`}
        onClick={handleUrlChange}
        id={
          deviceType === "Mobile" || deviceType === "Tablet"
            ? "mobile"
            : "desktop"
        }
      >

        <Router>
          <Routes>
            <Route
              path="/api/lobby/:token/:operatorid/:username"
              element={<LaunchLobby setLoader={setLoader} setLoaderSpeed={setLoaderSpeed} />}
            />
            <Route path="/sessionexpired" element={<SessionExpireMessage />} />
            <Route path="*" element={<NavigationComponent />} />
            {/* <Route path="/app/:error_type" element={<ServerError />} /> */}
            <Route path="/nickname" element={<Nickname />} />
            <Route path="/maintenance" element={<UnderMaintenance />} />
            <Route path="/accessdenied" element={<AccessDenied />} />
          </Routes>
        </Router>

      </div>

      {
        festiveTheme === 1 && <FestiveThemeBottomDesign />
      }


    </>
  );
}
export default withTranslation()(App);
