import {
  SET_COMMON_CONFIG, SET_CURRENT_STEP,SET_CLICK_STEP,SET_IS_SETTING_COACH_MARK,SET_IS_BANNER_AVAILABLE
} from "./actionTypes";

const initialState = {
  device_type: null,
  userLevel: null,
  currentStep:0,     // -1 
  isSettingCoachMark:false,
  clickStep:1,
  isBannerAvailable:''

};
const CommonConfig = (state = initialState, action) => {
  switch (action.type) {
    case SET_COMMON_CONFIG:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
      case SET_CURRENT_STEP:
      return {
        ...state,
        currentStep: action.payload
      };
      case SET_CLICK_STEP:
      return {
        ...state,
        clickStep: action.payload
      };
      case SET_IS_SETTING_COACH_MARK:
        return {
          ...state,
          isSettingCoachMark: action.payload
        };
        case SET_IS_BANNER_AVAILABLE:
      return {
        ...state,
        isBannerAvailable: action.payload,
      };
    default:
      return state;
  }
};

export default CommonConfig;
